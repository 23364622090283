import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/rXFpuVuWBbE">
    <SEO title="Desperate Times - If Not Us, Who?" />
  </Layout>
)

export default SermonPost
